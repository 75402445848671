import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/contact"
import './index.css';

export const query = graphql`
  query {
    file(relativePath: { eq: "camila-diaz.jpg" }) {
      childImageSharp {
        fixed(width: 250, height: 350, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

const IndexPage = ({ data }) => (
  <Layout>
    <SEO />
    <article className="content">
      <section id="work">
        <iframe
          title="Camila Diaz Video"
          width="640"
          height="360"
          src="https://www.youtube.com/embed/a0JMJVXloj4?controls=0&disablekb=1&hl=en&iv_load_policy=3&modestbranding=1&rel=0&showinfo=0&enablejsapi=1&origin=https%3A%2F%2Fcamdiaz.com&widgetid=1"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={false}
        ></iframe>
      </section>
      <section id="about">
        <Img fixed={data.file.childImageSharp.fixed} className="profile-pic" alt="Camila Diaz profile picture" />
        <p>
            Multimedia designer, Digital marketing consultant and content creator.
            My principal goal is to capture the spirit of your brand and give it a visual identity according to the way you want to be seen to the world.
            <br></br>
            <br></br>
            Graduated from escuela da vinci (Argentina) i create design pieces ready to be used in every platform you need: such as videos, brochures, logos, invitations and everything for you brand to make it grow.
        </p>
      </section>
      <section id="contact">
        <p>
          <a rel="noopener noreferrer" href="https://www.instagram.com/camiladiazdesign/" target="_blank">
            Follow my
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M352 0H160C71.648 0 0 71.648 0 160v192c0 88.352 71.648 160 160 160h192c88.352 0 160-71.648 160-160V160C512 71.648 440.352 0 352 0zm112 352c0 61.76-50.24 112-112 112H160c-61.76 0-112-50.24-112-112V160C48 98.24 98.24 48 160 48h192c61.76 0 112 50.24 112 112v192z"/>
              <path d="M256 128c-70.688 0-128 57.312-128 128s57.312 128 128 128 128-57.312 128-128-57.312-128-128-128zm0 208c-44.096 0-80-35.904-80-80 0-44.128 35.904-80 80-80s80 35.872 80 80c0 44.096-35.904 80-80 80z"/>
              <circle cx="393.6" cy="118.4" r="17.056"/>
            </svg>
            Instagram
          </a>
          <br></br>
          <br></br>
          For business inquiries or questions, fill out the following form or send me an email to: <a href="mailto:contact@camdiaz.com">contact@camdiaz.com</a>
          <br></br>
          <br></br>
          Thanks!
        </p>
        <Contact url="https://camdiaz.com/api/contact"/>
      </section>
    </article>
  </Layout>
)

export default IndexPage
