import React from "react"
import {useRef, useState} from "react"
import PropTypes from "prop-types"
import Recaptcha from 'react-recaptcha';
import './contact.css';

const Contact = ({url}) => {
  const captcha = useRef(null);
  const form = useRef(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
  const onSubmit = (event) => {
    setSuccess(false);
    setError(null);
    event.preventDefault();
    event.stopPropagation();
    event.preventDefault();
    if (!form.current.checkValidity()) {
      form.current.reportValidity();
    }
    captcha.current.execute();
    return true;
  };

  const onValidRecaptcha = async () => {
    const body = Object.fromEntries(new FormData(form.current).entries());
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json;charset=UTF-8',
        }
      });
      if (response.ok) {
        form.current.reset();
        setSuccess(true);
      } else {
        throw response;
      }
    } catch (error) {
      setSuccess(false);
      setError(error.msg || error.message || error);
    }
    captcha.current.reset();
  }

  return <form className="contact" method="POST" action={url} ref={form} onSubmit={onSubmit}>
    <Recaptcha
      ref={captcha}
      verifyCallback={onValidRecaptcha}
      sitekey="6Lfg2E4UAAAAADJWpv3aMYZcyb8KaHNKPbKscifP"
      render="explicit"
      size="invisible"
      onloadCallback={() => setRecaptchaLoaded(true)}
    />
    <input name="name" type="text" required placeholder="Name" aria-label="Name" />
    <input name="email" type="email" required placeholder="Email" aria-label="Email" />
    <textarea name="body" required aria-label="Inquirie body"></textarea>
    { success ? <span className="thanks">Thanks for your email</span> : null }
    { error ? <span className="error">{error}</span> : null }
    { recaptchaLoaded ? <button type="submit" aria-label="Send">
      Send
    </button> : null }
  </form>
}

Contact.propTypes = {
  url: PropTypes.string.isRequired,
}

export default Contact
